const HOST = process.env.REACT_APP_API_HOST || 'grocerysorter.com';

class APIService{
    static ClassifyGroceries(body){
        return fetch(`http://${HOST}:3003/classify`,{
            'method':'POST',
             headers : {
            'Content-Type':'application/json',
	     'Access-Control-Allow-Origin':'*',
	     },
      body:JSON.stringify(body)
    })
    .then(response => response.json())
    .catch(error => console.log(error))
    }

};
export default APIService;
